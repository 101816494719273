<template>
    <div class="content-listing--component">

        <div v-if="store.totalNumberOfRecords > 0" class="row">
            <VueDraggable
                ref="el"
                v-model="store.contentItems"
                @change="store.orderingHasChanged = true"
                :disabled="!store.orderingEnabled"
                :animation="650"
                ghostClass="ghost"
                class="draggable-holder"
            >
                <div
                    class="content-listing--card-item col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6"
                    :class="{'cursor-move': store.orderingEnabled}"
                    v-for="contentItem in store.contentItems"
                    :key="contentItem.id">
                    <content-listing-card
                        :content-item="contentItem">
                    </content-listing-card>
                </div>
            </VueDraggable>
        </div>
        <div v-if="store.showAlert">
            <div class="alert alert-info">
                <h5 class="justify-content-center">A keresés eredménytelen!</h5>
                <p><strong>Ismételje meg a keresést egyéb szempontok megadásával,</strong><br /> vagy
                    visszamehet az <a href="javascript:history.back()"> előző oldalra</a>, illetve <a href="/">kezdőlapra</a>
                </p>
            </div>
        </div>
    </div>
    <div class="row">
        <div
            v-if="isNextButtonVisible"
            class="col-12 text-center">
            <button
                @click="loadNextPage"
                type="button"
                class="btn btn-primary btn--site-primary">További oldalak betöltése
            </button>
        </div>
    </div>
</template>

<script>
import {useCustomContentStore} from '@/stores/CustomContentStore.js';
import {mapState} from 'pinia';
import {ref} from 'vue';
import jQuery from 'jquery';
import {debounce} from 'lodash';
import {VueDraggable} from 'vue-draggable-plus';
import ContentListingCard from '@/components/ContentListingCard.vue';
import {useCheckSession} from "@/composables/checkSession.js";
// import moment from "moment";

export default {
    name: 'ContentListing',
    setup(props) {

        const isDebug = false;

        const list = ref(null);
        const loadMoreStatus = ref('idle');
        const searchTerm = ref('');
        const store = useCustomContentStore();

        isDebug && console.log('ContentListing DEBUG starting... ');
        isDebug && console.log('ContentListing PROPS ', props);

        store.selectedOrderingField = props.orderingField;
        store.taxonomy = JSON.parse(props.requestTaxonomy);

        const debounceVariable = debounce((numberOfRecords) => {

            isDebug && console.log('Debouncing...');
            return numberOfRecords;
        }, 500);

        return {
            isDebug: isDebug,

            /**
             * Loads content list (dynamically) only once
             */
            loadingOnce: true,

            /**
             * The load was accomplished/done
             */
            alreadyLoaded: false,

            searchTerm,
            loadMoreStatus,

            list,
            store,

            // taxonomy: JSON.parse(props.requestTaxonomy),
            // taxonomiesList: props.taxonomiesList,
            // contentType: props.contentType ? props.contentType : 'pages',
            debounceVariable,
            anyFetchExecuted: false,

            // handlers
            storeUpdateSubscription: null,
            storeUpdateBySelectedProductTypeChangeSubscription: null,
            storeUpdateBySelectedPageSizeChangeSubscription: null
        };
    },
    props: {
        requestTaxonomy: {
            type: String,
            default: '{}',
        },
        taxonomiesList: {
            type: String,
            default: '{}',
        },
        orderingField: {
            type: String,
            default: '',
        },
        contentType: {
            type: String,
            default: 'pages',
        },
        debugEnabled: {
            type: Boolean,
            default: false,
        },
        fetchNews: {
            type: Boolean,
            default: false,
        },
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    },
    /**
     *
     */
    mounted() {

        this.isDebug && console.log('ContentListing component mounted.');
        this.isDebug && console.log('Taxonomies list: ', this.taxonomiesList);
        this.isDebug && console.log('Taxonomies for this query: ', this.store.taxonomy);
        this.isDebug && console.log('LocalStorage: ', localStorage);

        this.store.removeContentItems();

        this.subscribeStoreUpdateBySearchTerm();
        this.subscribeStoreUpdateByForce();
        this.subscribeStoreUpdateBySelectedPageSizeChange();
        this.subscribeStoreUpdateByProductTypeChange();

        this.isDebug && console.log('Content Items: ',
            this.store.totalNumberOfRecords,
            this.store.contentItems.length
        );
        this.store.forceSearch = false;
        this.store.resetContentItems();
        this.fetchContentItems();
    },
    methods: {
        /**
         *
         */
        loadNextPage() {
            this.loadMoreStatus = 'loading';
            jQuery('#bottom-delimiter').addClass('spinner-border');
            this.fetchContentItems();
        },
        /**
         *
         * @param tag
         * @returns {*|string}
         */
        getTaxonomyText(tag) {
            const selectedCategory = JSON.parse(this.taxonomiesList)
                .filter(item => {
                    return item.key === tag.category;
                });

            if (selectedCategory.length > 0) {
                return selectedCategory[0].value;
            }
            return '';
        },
        /**
         *
         */
        subscribeToPageOnScroll() {
            /**
             * Register handler for page ONSCROLL event
             */
            window.addEventListener('scroll', (event) => this.handleOnScroll(event));
        },
        /**
         *
         * @param event
         */
        handleOnScroll(event) {

            if (this.loadingOnce) {
                // loadingOnce uzemmod
                if (this.alreadyLoaded) {
                    // mar lefutott egyszer
                    return;
                }
            }
            this.alreadyLoaded = true;

            // is new search indeed?
            // this.store.isNewSearch = true;
            this.store.triggeredBySearchTerm = false;
            this.store.fetchBySearchEnabled = true;

            const delimiterElement = document.getElementById('bottom-delimiter');
            const delimiterElementPosition = delimiterElement.getBoundingClientRect();

            if (
                this.store.totalNumberOfRecords > this.store.pageSize &&
                this.loadMoreStatus === 'idle' &&
                delimiterElementPosition.top < window.innerHeight
            ) {
                this.loadNextPage();
            }
        },

        /**
         *
         */
        subscribeStoreUpdateBySearchTerm() {

            this.isDebug && console.log('Subscribe to store subscribeStoreUpdateBySearchTerm.');

            this.storeUpdateSubscription = this.store.$subscribe((mutation, state) => {

                this.isDebug && console.log('subscribeStoreUpdateBySearchTerm() called...');
                if (this.store.triggeredBySearchTerm &&
                    this.store.fetchBySearchEnabled) {

                    this.isDebug && console.log('First ajax contact call...');
                    this.store.fetchBySearchEnabled = false;
                    this.fetchContentItems();
                } else {
                    this.isDebug && console.log('Event not for subscribeStoreUpdateBySearchTerm...');
                }
            });

        },

        /**
         *
         */
        subscribeStoreUpdateBySelectedPageSizeChange() {

            this.isDebug && console.log('Subscribe to store subscribeStoreUpdateBySelectedPageSizeChange.');

            this.storeUpdateBySelectedPageSizeChangeSubscription = this.store.$subscribe((mutation, state) => {
                this.isDebug && console.log('subscribeStoreUpdateBySelectedPageSizeChange() called.');
                if (this.store.triggeredByPageSizeChanged) {
                    this.isDebug && console.log("Triggered by page size change.");
                    this.store.fetchByPageSizeEnabled = false;
                    this.fetchContentItems();
                } else {
                    this.isDebug && console.log('Event not for storeUpdateBySelectedPageSizeChangeSubscription...');
                }
            });
        },
        subscribeStoreUpdateByProductTypeChange() {

            this.isDebug && console.log('Subscribe to store subscribeStoreUpdateByProductTypeChange.');

            this.storeUpdateBySelectedProductTypeChangeSubscription = this.store.$subscribe((mutation, state) => {
                this.isDebug && console.log('storeUpdateBySelectedProductTypeChangeSubscription() called.');
                if (this.store.triggeredByProductCategory) {
                    this.isDebug && console.log("Triggered by product category change.");
                    this.store.fetchByProductCategoryEnabled = true;
                    this.fetchContentItems();
                } else {
                    this.isDebug && console.log('Event not for storeUpdateBySelectedProductTypeChangeSubscription...');
                }
            });
        },
        /**
         *
         */
        unsubscribeStoreUpdateBySearchTerm() {
            this.isDebug && console.log('Unsubscribe store mutation.');
            this.subscribeStoreUpdateBySearchTerm = null;
        },

        /**
         *
         */
        subscribeStoreUpdateByForce() {

            this.isDebug && console.log('Subscribe to store mutation (force).');

            this.storeUpdateSubscription = this.store.$subscribe((mutation, state) => {

                if (this.store.forceSearch) {
                    // elsot engedi at csak...
                    this.store.forceSearch = false;
                    this.store.resetContentItems();
                    this.fetchContentItems();
                }
            });
        },
        /**
         *
         * @param requestedPageSize
         * @returns {number|*}
         */
        calculateAvailablePageSize(requestedPageSize) {

            var totalNumberOfRecords = parseInt(this.store.totalNumberOfRecords, 10);
            var contentItemsLength = parseInt(this.store.contentItems.length, 10);

            this.isDebug && console.log("calculateAvailablePageSize: ",
                requestedPageSize,
                totalNumberOfRecords,
                contentItemsLength
            );

            if (0 === contentItemsLength) {
                this.isDebug && console.log('calculatedPageSize is: ',
                    requestedPageSize,
                    ', total number of records: ', totalNumberOfRecords
                );
                return requestedPageSize;
            }

            if (totalNumberOfRecords - contentItemsLength >= requestedPageSize) {
                this.isDebug && console.log('calculatedPageSize is: ',
                    requestedPageSize,
                    ', total number of records: ',
                    totalNumberOfRecords
                );
                return requestedPageSize;
            }

            this.isDebug && console.log('calculatedPageSize is ',
                totalNumberOfRecords - contentItemsLength,
                ', total number of records ',
                totalNumberOfRecords
            );
            return totalNumberOfRecords - contentItemsLength;
        },

        /**
         * Fetches content items, to populate the elements listing
         */
        fetchContentItems() {

            this.isDebug &&
            this.isDebug && console.log('fetchContentItems() started. Fetching news: ',
                this.fetchNews) &&
            this.isDebug && console.log(JSON.stringify(this.loadMoreStatus));

            /**
             * Amennyiben korabbi API hivas hatasara van contentItem.length
             *
             * @type {number}
             */
            const storeSize = this.store.contentItems.length;
            this.isDebug && console.log('Store size is: ', storeSize);

            if (this.store.requestInitializationCallDone && !storeSize) {

                /**
                 * Amennyiben 0 eredmeny jon az api-bol akkor nem kell spinnelnie
                 */
                this.isDebug && console.log('Endpoint has empty data.');

                jQuery('.statically__generated')
                    .toggleClass('hide-transition')
                    .toggleClass('show-transition')
                    .show();
                jQuery('#bottom-delimiter').removeClass('spinner-border');
                this.loadMoreStatus = 'idle';
                return;
            }

            var requestedPageSize = this.getRequestedPageSize();

            this.showBriefDebugInfo('Before setting values....');
            if (this.store.triggeredBySearchTerm) {
                this.store.contentFromValue = 0;
                // we are before fetch, before search
                this.store.initializeForNewSearch();
                this.showBriefDebugInfo('After setting values....');
            }

            if (this.store.triggeredByPageSizeChanged) {
                this.store.contentFromValue = 0;
                // we are before fetch, before search
                this.store.initializeForNewSearch();
                this.showBriefDebugInfo('After setting values....');
            }

            if (this.store.triggeredByProductCategory) {
                this.store.contentFromValue = 0;
                // we are before fetch, before search
                this.store.initializeForNewSearch();
                this.showBriefDebugInfo('After setting values....');
            }

            if (storeSize > 0 &&
                (storeSize >= this.store.totalNumberOfRecords)) {

                /**
                 * nem az elso hivasrol van szo,
                 * azaz mar vannak elemek a store-ban
                 */
                this.loadMoreStatus = 'list-ended';
                this.store.contentFromValue = this.store.totalNumberOfRecords;
                jQuery('#bottom-delimiter').removeClass('spinner-border');
                return;
            }
            this.isDebug && console.log('Taxonomy before preparation: ', this.store.taxonomy);

            if (this.store.triggeredBySearchTerm) {
                this.store.triggeredBySearchTerm = false;
            }

            if (this.store.triggeredByPageSizeChanged) {
                this.store.triggeredByPageSizeChanged = false;
            }

            if (this.store.triggeredByProductCategory) {
                this.store.triggeredByProductCategory = false;
            }

            if (this.store.forceSearch) {
                this.store.forceSearch = false;
            }
            this.showBriefDebugInfo('Before Store appendFetchedItems...');
            this
                .store
                .appendFetchedItems(
                    this.store.prepareRequestData({
                        from: this.store.contentFromValue,
                        searchTerm: this.store.searchTerm,
                        pageSize: this.calculateAvailablePageSize(requestedPageSize),
                        taxonomy: this.store.taxonomy,
                        order: this.orderingField,
                        contentType: this.contentType,
                        fetchNews: this.fetchNews,
                    }),
                    this.fetchNews)
                .then(
                    response => {
                        this.isDebug && console.log('New store size: ', this.store.contentItems.length);
                        // if (0 === this.store.contentItems.length) {
                        //     console.log("Hiding static DOM elements..");
                        // }

                        jQuery('.statically__generated').hide();
                        jQuery('.list-item--card-holder', '.statically__generated').remove();
                        this.store.searchInProgress = false;
                        this.store.contentFromValue = this.store.contentItems.length;
                        this.loadMoreStatus = 'idle';
                        jQuery('#bottom-delimiter').removeClass('spinner-border');
                        this.anyFetchExecuted = true;
                    })
                .catch(
                    error => {
                        this.isDebug && console.log('ERROR ', error);
                    });
        },

        /**
         *
         * @param myNote
         */
        showBriefDebugInfo(myNote = '') {
            this.isDebug && console.log(
                myNote ? myNote + '\n' : '',
                '** This is a new search/request:', this.store.isNewSearch, '\n',
                '** Triggered by search term:', this.store.triggeredBySearchTerm, '\n',
                '** Requested page size ', this.getRequestedPageSize(), '\n',
                '** Store size is: ', this.store.contentItems.length, '\n',
                '** Total number of records on backend:', this.store.totalNumberOfRecords, '\n',
                '** REQUEST TAXONOMY: ', this.store.taxonomy, '\n',
            );
            this.isDebug && console.log(
                myNote ? myNote + '\n' : '',
                '** This is a new search/request:', this.store.isNewSearch, '\n',
                '** Triggered by search term:', this.store.triggeredBySearchTerm, '\n',
                '** Requested page size ', this.getRequestedPageSize(), '\n',
                '** Store size is: ', this.store.contentItems.length, '\n',
                '** Total number of records on backend:', this.store.totalNumberOfRecords, '\n',
                '** REQUEST TAXONOMY: ', this.store.taxonomy, '\n',
            );
        },
        /**
         *
         * @returns {number|number}
         */
        getRequestedPageSize() {
            // return (0 === this.store.contentItems.length) ?
            //     this.store.pageSize * 2 :
                return this.store.pageSize;
        },
        getElement(selector) {
            return jQuery(selector);
        },
        /**
         *
         * @param elementSelector
         * @param targetCssClass
         * @returns {jQuery|HTMLElement|*}
         */
        setCssClass(elementSelector, targetCssClass) {
            const element = this.getElement(elementSelector);
            if (!element.hasClass(targetCssClass)) {
                jQuery(element.addClass(targetCssClass));
            }
            return element;
        },
        /**
         *
         * @param elementSelector
         * @param targetCssClass
         * @returns {jQuery|HTMLElement|*}
         */
        unsetCssClass(elementSelector, targetCssClass) {
            const element = this.getElement(elementSelector);
            if (!element.hasClass(targetCssClass)) {
                element.removeClass(targetCssClass);
            }
            return element;
        },
        /**
         *
         * @returns {jQuery|HTMLElement|*}
         */
        prepareAppear(targetSelector) {
            return this
                .setCssClass(targetSelector, 'show-transition')
                .unsetCssClass(targetSelector, 'hide-transition')
                ;
        },
        /**
         *
         * @returns {jQuery|HTMLElement|*}
         */
        prepareDisappear(targetSelector) {
            return this
                .setCssClass(targetSelector, 'hide-transition')
                .unsetCssClass(targetSelector, 'show-transition')
                ;
        },
    },
    /**
     *
     */
    computed: {
        ...mapState(useCustomContentStore, ['contentItems']),
        /**
         *
         * @returns {boolean}
         */
        isNextButtonVisible() {

            const pageSize = parseInt(this.store.pageSize, 10);
            const totalNumberOfRecords = parseInt(this.store.totalNumberOfRecords, 10);
            const contentItemsLength = parseInt(this.store.contentItems.length, 10);

            var result = false;

            if (totalNumberOfRecords > pageSize) {
                if (undefined !== this.store.contentItems) {
                    if (false === this.anyFetchExecuted) {
                        result = true;
                    }
                    if (contentItemsLength < totalNumberOfRecords) {
                        result = true;
                    }
                }
            }

            return result;
        },
        /**
         *
         * @returns {function(*): *[]}
         */
        getAllTags() {
            return (record) => {

                const tags = [];
                if (Object.entries(record.taxonomies).length) {
                    Object.keys(record.taxonomies).forEach(category => {
                        if (Object.keys(record.taxonomies[category]).length) {
                            Object.keys(record.taxonomies[category]).forEach(key => {
                                tags.push({
                                    category,
                                    key,
                                    value: record.taxonomies[category][key],
                                });
                            });
                        }
                    });
                }

                return tags;
            };
        },
    },

    components: {
        'content-listing-card': ContentListingCard,
        VueDraggable
    }
};
</script>


<style scoped>
.teaser {
    font-size: 16px;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
    position: absolute;
}
</style>
