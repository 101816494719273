<template>
    <div class="list-item--card-holder show-transition">
        <div class="content">
            <div class="row list-item--card">
                <div class="col-lg-4">
                    <div
                        class="list-item--image-holder"
                        v-if="contentItem.contentType !== 'letoltesek'">
                        <div class="list-item--image">
                            <img
                                class="thumbnail"
                                :src="imagePath(contentItem)"
                                :alt="contentItem.fields.title" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="card-elements--holder">
                        <div class="list-item--teaser">
                            <h2 class="list-item--title">
                                <i class="bi bi-octagonx"></i>
                                <a :href="`/${contentItem.fields.slug}`">{{ contentItem.fields.title }}</a>
                            </h2>
                            {{ contentItem.fields.teaser }}
                            <div v-if="contentItem.contentType==='letoltesek'">
                                <button
                                    type="button"
                                    class="btn btn-info">Letöltés
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4"></div>
                <div class="col-lg-8">
                    <ul class="utils">
                        <li v-if="contentItem.contentType !=='letoltesek'">
                            <a
                                :href="`/${contentItem.contentType}/${contentItem.fields.slug}`"
                                class="btn btn-primary btn--site-primary">Részletek</a>
                        </li>
                        <li class="date-time">{{ formattedDate(contentItem) }}</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="admin-tools" v-if="store.canAccessBackend()">
                        <div>
                            <strong>Hírek oldalon:</strong> {{ contentItem.show_on_news_page ? 'igen' : 'nem' }}
                        </div>
                        <div
                            :class="[
                            'edit-record--holder',
                             'utility--frontend-button',
                             {'hidden': !store.componentVisible}
                             ]">
                            <a
                                :href="`/bolt/edit/${contentItem.id}`"
                                target="_blank"
                                class="edit-record--button btn btn-light"><i
                                class="bi bi-pencil"></i> {{ contentItem.id }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { useCustomContentStore } from '@/stores/CustomContentStore.js';

export default {

    name: 'ContentListingCard',
    setup(props) {

        const isDebug = false;
        const store = useCustomContentStore();
        return {
            isDebug: isDebug,
            migrationMode: props.migrationMode,
            store,
        };
    },
    props: {
        contentItem: {
            type: Object,
            default: null,
        },
        migrationMode: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        /**
         *
         * @param contentItem
         * @returns {string|undefined}
         */
        imagePath(contentItem) {
            return ('' === _.get(contentItem, 'fields.image.thumbnail')) ?
                '/site/images/picture-not-found.webp' :
                _.get(contentItem, 'fields.image.thumbnail');
        },
        /**
         *
         * @param contentItem
         * @returns {*|boolean}
         */
        isPageMigrated(contentItem) {

            return contentItem.fields.page_migrated && true === contentItem.fields.page_migrated;
        },
        formattedDate(myDate) {
            return myDate.modifiedAt.date.substring(0, 10);
            // return moment(myDate.modifiedAt.date).format("YYYY-MM-dd");
        },
    },

};
</script>

<style lang="css" scoped>
.list-item--card-holder .hidden {
    display: none;
}
</style>
