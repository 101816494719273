<template>
    <div
        :class="[
        'utility-menu--holder',
        'align-middle',
        {'hidden': !store.componentVisible}
        ]">
        <div class="form-check form-switch">
            <input
                class="form-check-input btn-success"
                type="checkbox"
                role="switch"
                id="utility-menu-switch"
                @click="toggleEditor()">
            <label
                class="form-check-label"
                for="utility-menu-switch">Beállítások</label>
        </div>

        <div
            class="editor--tool btn-group"
            v-if="true===store.editorToolEnabled">
            <button
                class="btn btn-success"
                @click="toggleOrderingEnabled()">Sorrendezés {{ store.orderingEnabled ? 'kikapcsolása' : 'engedélyezése' }}
            </button>

            <button
                class="btn btn-success"
                :disabled="true!==store.orderingHasChanged"
                @click="saveOrdering()">Sorrend mentése
            </button>

            <button
                class="btn btn-success"
                :disabled="!this.store.orderingHasChanged"
                @click="resetChanges()">Sorrend helyreállítása
            </button>
        </div>
    </div>
</template>

<script>
import {useCustomContentStore} from '@/stores/CustomContentStore.js';

import jQuery from 'jquery';
import {useCheckSession} from '@/composables/checkSession.js';
// import { ref } from 'vue';
// import _ from 'lodash';

export default {
    name: 'UtilityMenu',
    setup(props) {
        const isDebug = false;
        const store = useCustomContentStore();
        store.requestTaxonomy = JSON.parse(props.requestTaxonomy || '{}');

        return {
            isDebug,
            store,
            // taxonomy: JSON.parse(props.requestTaxonomy || '{}'),
            // componentVisible ,
            // editorToolEnabled: ref(false),
            // backupContentItems: [],
            // contentItemsHasChanged: ref(false),
        };
    },
    mounted() {


        // try {
        //     const taxonomyObject = JSON.parse(JSON.parse(this.requestTaxonomy));
        //     const keys = Object.keys(taxonomyObject);
        //     this.store.orderingField1 = keys[0];
        //     this.store.orderingField2 = taxonomyObject[this.store.orderingField1]["slug"][0];
        // } catch (exception) {
        //     console.log('Exception: ', exception);
        // }
    },
    props: {
        requestTaxonomy: {
            type: String,
            default: '{}',
        },
    },
    methods: {
        /**
         *
         */
        resetChanges() {
            if (this.storeHasChanged()) {
                this.rollbackStoreContent();
                return;
            }
            this.isDebug && console.log('resetChanges(): No changes.');
        },
        /**
         *
         */
        toggleEditor() {
            this.isDebug && console.log('toggleEditor() to ', !this.store.editorToolEnabled);

            if (this.store.editorToolEnabled) {
                const disableEditorTool = confirm('Kilép a beállításokból?');
                if (!disableEditorTool) {
                    return;
                }
            }

            this.store.editorToolEnabled = !this.store.editorToolEnabled;

            if (true === this.store.editorToolEnabled) {
                this.store.backupContentItems = [...this.store.contentItems];
                return;
            }

            // uj allapot false: editor kikapcsolva
            if (!this.store.orderingEnabled) {
                return;
            }
            if (!this.store.orderingHasChanged) {
                return;
            }

            this.handleSaveOrdering();
            this.store.orderingEnabled = false;
        },
        /**
         *
         * @param forceSave
         * @returns {boolean}
         */
        handleSaveOrdering(forceSave = false) {

            if (!forceSave && !this.storeHasChanged()) {
                return true;
            }
            if (this.storeHasChanged()) {

                const saveOrdering = confirm('A cikkek sorrendezése időközben megváltozott. Elmenti a változást?');
                if (saveOrdering) {
                    this.saveOrdering();
                    return true;
                } else {
                    this.rollbackStoreContent();
                    return false;
                }
            }
            this.saveOrdering();

        },

        /**
         *
         */
        saveOrdering() {

            const orderingEnabled = this.store.orderingEnabled ? true : false;

            if (!orderingEnabled) {
                this.isDebug && console.log('Ordering disabled! Returning...');
                return;
            }

            const orderingData = this.store.contentItems.map(contentItem => {
                return contentItem.id;
            });

            try {
                const taxonomyObject = JSON.parse(this.store.requestTaxonomy);

                // const keys = Object.keys(this.store.requestTaxonomy);
                // const field1Pieces = [];
                // const field2Pieces = [];

                // keys.map(item => {
                //     console.log(taxonomyObject[item]);
                //     field1Pieces.push(item);
                //     field2Pieces.push(taxonomyObject[item]["slug"]);
                // })

                // this.store.orderingField1 = field1Pieces.join('__');
                // this.store.orderingField2 = field2Pieces.join('__');
                // console.log("orderingField1 ", this.store.orderingField1);
                // console.log("orderingField2 ", this.store.orderingField2);

                // console.log("KEYS ", keys);
                // this.store.orderingField1 = keys[0];
                // this.store.orderingField2 = taxonomyObject[this.store.orderingField1]["slug"][0];

                // TODO: validate/check if field exists...
                this.store.saveOrdering({
                    // orderingField1: this.store.orderingField1,
                    // orderingField2: this.store.orderingField2,
                    taxonomyObject,
                    items: [...orderingData],
                });

            } catch (exception) {
                this.isDebug && console.log('Exception: ', exception);
            }


        },

        /**
         *
         */
        rollbackStoreContent() {
            // if(null === this.store.contentItems ) {
            //     return;
            // }
            this.isDebug && console.log('rollbackStoreContent() Rollback.');
            this.store.contentItems = [...this.store.backupContentItems];
            this.store.orderingHasChanged = false;
        },
        /**
         *
         * @returns {boolean}
         */
        toggleOrderingEnabled() {
            if (true === this.store.orderingEnabled) {

                // attempt to disable ordering
                this.handleSaveOrdering();
                this.store.orderingEnabled = false;
                this.isDebug && console.log('toggleOrderingEnabled() to false/disabled');
                return false;
            }

            this.isDebug && console.log('toggleOrderingEnabled(): atvaltok DOM tartalomra');
            this.store.pageSize = 1000;
            this.store.initialPageSize = 2000;
            jQuery('#bottom-delimiter').addClass('spinner-border');
            this.fetchContentItems();
            this.isDebug && console.log('toggleOrderingEnabled() to true/enabled');
            this.store.orderingEnabled = true;

        },
        /**
         *
         */
        orderHasChanged() {
            this.isDebug && console.log('New order....');
            this.store.orderingHasChanged = true;
        },
        /**
         *
         * @returns {UnwrapRef<boolean>}
         */
        storeHasChanged() {
            return this.store.orderingHasChanged;
        },
        /**
         *
         */
        fetchContentItems() {

            this
                .store
                .appendFetchedItems(
                    this.store.prepareRequestData({
                        from: 0,
                        searchTerm: '',
                        pageSize: this.store.pageSize,
                        taxonomy: this.store.requestTaxonomy,
                        order: '-id',
                        contentType: 'pages',
                        fetchNews: false,
                    }),
                    false)
                .then(
                    () => {
                        this.isDebug && console.log('New store size: ', this.store.contentItems.length);
                        // if (0 === this.store.contentItems.length) {
                        //     console.log("Hiding static DOM elements..");
                        // }

                        jQuery('.statically__generated').hide();
                        jQuery('.list-item--card-holder', '.statically__generated').remove();
                        this.store.searchInProgress = false;
                        jQuery('#bottom-delimiter').removeClass('spinner-border');
                        this.store.backupContentItems = [...this.store.contentItems];
                    })
                .catch(
                    error => {
                        this.isDebug && console.log('ERROR ', error);
                    });
        },

    },
};
</script>

<style lang="css" scoped>
.utility-menu--holder {
    display: flex;
    flex-flow: row;
    padding: 10px 0;
    .form-switch {
        display: flex;
        align-items: center;
        margin: 10px;
        label { margin-left: 10px;}
    }

    &.hidden {
        display: none;
    }
}
</style>
