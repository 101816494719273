<template>
    <div v-if="repositorySize">Cikkek száma: {{store.totalNumberOfRecords}}</div>
</template>

<script>
import { useCustomContentStore } from '@/stores/CustomContentStore.js';

export default {
    name: 'DisplayResultsNumber',
    setup(props) {

        const isDebug = false;
        const store = useCustomContentStore();
        store.totalNumberOfRecords = props.initialValue;

        isDebug && console.log("initial value for total number of records: ", store.totalNumberOfRecords);

        return {
            isDebug,
            store
        };
    },
    props: {
        initialValue: {
            type: Number,
            default: 0
        }
    },
    computed : {
        repositorySize() {
            return this.store.totalNumberOfRecords;
        }
    },
    mounted() {
        this.store.totalNumberOfRecords = this.initialValue;
    }
};
</script>

<style lang="scss" scoped>

</style>
