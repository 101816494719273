<template>
    <div>
        <form :action="formAction">
            <div>Kattintson a termékkategória kiválasztásához</div>
            <v-select
                :items="normalizedProductTypes"
                v-model="selectedProductType"
                :model-value="selectedProductTypeValue"
                :return-object="true"
                @update:modelValue="selectProductType();filterDownloads()"
            ></v-select>
            <ul class="download-type--selector">
                <li
                    v-for="(downloadType, key) in downloadTypesList"
                    :key="key"
                    :class="cssClass(downloadType)"
                >
                    <a
                        @click="selectDownloadType(downloadType, key);filterDownloads()"
                    >
                        <img :src="`/files/pictograms/${key}.webp`" />
                        <h5>{{ downloadType }}</h5>
                    </a>
                </li>
            </ul>
        </form>
        <div class="items-list--container download--items-list">
            <div class="number--of--results">Cikkek száma: {{ filteredDownloads.length }}</div>
            <div class="row">
                <div
                    class="col-lg-6"
                    v-for="(download, key) in filteredDownloads"
                    :key="key">
                    <div class="list-item--card-holder downloads show-transition">
                        <div class="content">
                            <div class="list-item--card">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card-elements--holder">
                                            <div class="list-item--teaser">
                                                <h2 class="list-item--title downloads">
                                                    <i class="bi bi-octagonx"></i>
                                                    {{ download.fieldValues.title }}
                                                </h2>
                                                {{ download.fieldValues.teaser }}
                                                <div class="record--description">
                                                </div>
                                                <div class="download-list--holder">
                                                    <div
                                                        class="download--holder"
                                                        v-for="(file, key) in fileList(download)"
                                                        :key="key">

                                                        <a
                                                            target="_blank"
                                                            class="download-icon"
                                                            :href="file.url">
                                                            <img
                                                                :src="`/files/pictograms/` + fileIcon(file)"
                                                                :alt="file.title" /></a>
                                                        <span>{{ file.title }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4"></div>
                                    <div class="col-lg-8">
                                        <ul class="utils">
                                            <li class="date-time" style="width: 100%">{{ formattedDate(download.createdAt) }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="admin-tools" v-if="store.canAccessBackend()">
                                            <div>
                                                <strong>Hírek oldalon:</strong> {{ download.show_on_news_page ? 'igen' : 'nem' }}
                                            </div>
                                            <div class="edit-record--holder  utility--frontend-button">
                                                <a
                                                    :href="`/bolt/edit/${download.id}`"
                                                    target="_blank"
                                                    class="edit-record--button btn btn-light"><i
                                                    class="bi bi-pencil"></i> {{ download.id }}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCustomContentStore } from '@/stores/CustomContentStore.js';
import { VSelect } from 'vuetify/components';
import { ref } from 'vue';

export default {
    name: 'DownloadsFilter',
    setup(props) {
        const store = useCustomContentStore();
        const downloads = JSON.parse(props.downloads);

        return {
            store,
            selectedDownloadType: null,
            selectedProductType: null,
            selectedProductTypeValue : ref(""),
            productTypesList: JSON.parse(props.productTypes),
            downloadTypesList: JSON.parse(props.downloadTypes),
            downloads: [...downloads.items],
            filteredDownloads: ref([...downloads.items])
        };
    },
    props: {
        productTypes: {
            type: String,
            default: '{}',
        },
        downloadTypes: {
            type: String,
            default: '{}',
        },
        formAction: {
            type: String,
            default: '',
        },
        downloads: {
            type: String,
            default: '[]',
        },
    },
    computed: {

        fileIcon() {
            return (file) => {
                switch (file.extension) {
                    default:
                        return 'download_generic.webp';

                    case 'doc':
                    case 'docx':
                    case 'odt':
                        return 'download_word.webp';

                    case 'xls':
                    case 'xlsx':
                    case 'ods':
                        return 'download_excel.webp';

                    case 'graph':
                    case 'jpeg':
                    case 'jpg':
                    case 'png':
                    case 'tif':
                    case 'tiff':
                    case 'bmp':
                        return 'download_graph.webp';

                    case 'pdf':
                        return 'download_pdf.webp';

                    case 'cad':
                        return 'download_cad.webp';

                    case 'bim':
                        return 'download_bim.webp';
                }
            };
        },
        fileList() {
            return (download) => {
                return download.fieldValues.download__file_list;
            };
        },

        cssClass() {
            return (downloadType, defaultCssClasses) => {
                return downloadType === this.selectedDownloadType ?
                    ['active', defaultCssClasses] :
                    [defaultCssClasses];
            };
        },
        /**
         *
         * @returns {UnwrapRef<Ref<UnwrapRef<number>>>|*}
         */
        repositorySize() {
            return (this.store.totalNumberOfRecords) ?
                this.store.totalNumberOfRecords :
                this.initialValue;
        },
        normalizedProductTypes() {
            const optionNames = Object.keys(this.productTypesList);
            return optionNames.map(optionName => {
                const optionTitle = this.productTypesList[optionName];
                return {
                    value: optionName,
                    title: optionTitle,
                };
            });
        },
    },
    mounted() {

    },
    components: {
        'v-select': VSelect,
    },
    methods: {
        selectProductType() {
           this.selectedProductTypeValue = this.selectedProductType;
        },
        filterDownloads() {

            var filteredDownloads = this.downloads;

            if (null !== this.selectedProductType) {
                filteredDownloads = filteredDownloads.filter((download) => {
                    const taxonomies = download.taxonomyValues;
                    if (taxonomies['product_types']) {
                        const taxonomyName = Object.keys(taxonomies['product_types'])[0];
                        return taxonomyName === this.selectedProductType.value;
                    }
                });
            }
            if (null !== this.selectedDownloadType) {
                filteredDownloads = filteredDownloads.filter((download) => {
                    const taxonomies = download.taxonomyValues;
                    if (taxonomies['download_types']) {
                        const taxonomyName = Object.keys(taxonomies['download_types'])[0];
                        const taxonomyValue = taxonomies['download_types'][taxonomyName];
                        return taxonomyValue === this.selectedDownloadType;
                    }
                });
            }
            this.filteredDownloads = [...filteredDownloads];

            return this.filteredDownloads ;
        },
        formattedDate(myDate) {
            return myDate.substring(0, 10);
            // return moment(myDate.modifiedAt.date).format("YYYY-MM-dd");
        },
        /**
         *
         * @param contentItem
         * @returns {*|boolean}
         */
        isPageMigrated(download) {

            return true;
            //download.fieldValues.page_migrated && true === download.fields.page_migrated;
        },
        /**
         *
         * @param downloadType
         * @param key
         */
        selectDownloadType(downloadType, key) {
            this.selectedDownloadType = downloadType;
        },
    },

};
</script>

<style lang="scss" scoped>

</style>
