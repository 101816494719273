// import axios from '@/services/customAxios.js';

import _ from 'lodash';

export function useCheckSession() {

  const fetchOptions = {

    body: null,
    options: {
      method: 'POST',
      redirect: 'manual',
    },
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  };

  /**
   *
   * @param serviceUrl
   * @param customOptions
   * @returns {Promise<Response>}
   */
  function check(serviceUrl = '/api/check-session', customOptions = {}) {

    try {
      return fetch(serviceUrl, {
        redirect: 'manual',
        ...fetchOptions,
        ...customOptions,
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  /**
   *
   * @returns {Promise<boolean>}
   */
  async function fetchSession() {
    try {

      const response = await this.check();
      // console.log("Ajax response", response);
      if (response.type === 'opaqueredirect' && response.status === 0) {
        // console.log('componentVisible() ', false);
        return false;
      }

      if (response.type === 'basic' && response.status === 200) {
        // const streamData = [];
        // for await (const chunk of response.body) {
        //   streamData.push(chunk);
        // }
        // console.log(
        //   streamData.forEach(data => console.log( data, String.fromCharCode(data)))
        // );
        return true;
      }

      const data = await response.json();
      const roles = _.get(data, '0.roles', []);
      // console.log('componentVisible() ', data, roles.includes('ROLE_WEBSERVICE'));
      // return roles.includes('ROLE_WEBSERVICE');
      return true;
    } catch (exception) {
      console.log('ERROR: ', JSON.stringify(exception));
      // console.log('componentVisible() ', false);
      return false;
    }
  }

  return { check, fetchSession, fetchOptions };
}
