import {defineStore} from 'pinia';
import {ref} from 'vue';
import _ from 'lodash';
import CustomContentService from '@/services/CustomContentService.js';

export const useCustomContentStore = defineStore({
  id: 'CustomContentStore',
  /**
   *
   */
  state() {

    const isDebug = false;
    const defaultPageSize = 6;
    const pageSizeList = [6, 12, 18, 24, 48, 96];

    var pageSize = localStorage.getItem('pageSize');

    if (null === pageSize) {
      localStorage.setItem('pageSize', defaultPageSize);
      pageSize = defaultPageSize;
    }

    if (!pageSizeList.includes(parseInt(pageSize, 10))) {
      localStorage.setItem('pageSize', defaultPageSize);
    }

    localStorage.setItem('initialPageSize', 2 * localStorage.getItem('pageSize'));

    isDebug && console.log("LOCAL STORAGE is: ", JSON.stringify(localStorage.getItem('pageSize')));

    return {
      isDebug,
      requestInitializationCallDone: false,
      forceSearch: false,
      searchLoaderIsActive: false,

      pageSize: localStorage.getItem('pageSize'),
      initialPageSize: localStorage.getItem('initialPageSize'),
      prevPageSize: localStorage.getItem('defaultPageSize') || defaultPageSize,
      defaultPageSize,
      contentFromValue: 0,
      // Search can be triggered by SEARCH or by SCROLL
      // this flag is true when search is triggered by searchTerm typing
      triggeredBySearchTerm: false,
      triggeredByPageSizeChanged: false,
      triggeredByProductCategory: false,

      // the fetch by searchTerm can be executed
      fetchBySearchEnabled: false,
      fetchByPageSizeEnabled: false,
      fetchByProductCategoryEnabled: false,

      totalNumberOfRecords: ref(0),
      searchTerm: ref(''),
      prevSearchTerm: ref(''),
      contentItems: ref([]),
      control: 'Hello mazli 10',

      selectedOrderingField: "ordering_main_category",

      // is true when the searchTerm is a new searchTerm
      isNewSearch: true,

      // the search loading indicator status
      searchInProgress: false,

      showAlert: false,

      orderingEnabled: false,
      editorToolEnabled: false,
      orderingHasChanged: false,
      backupContentItems: null,

      componentVisible: false,
      backendSession: false,
      backendRoles: [],

      defaultTaxonomy: {},
      taxonomy: {},
      requestTaxonomy:{}
    }
  },
  /**
   *
   */
  getters: {

    /**
     *
     * @returns {{totalNumberOfRecords: Ref<UnwrapRef<number>>, editorToolEnabled: boolean, orderingHasChanged: boolean, fetchByPageSizeEnabled: boolean, backupContentItems: null, fetchBySearchEnabled: boolean, orderingEnabled: boolean, searchInProgress: boolean, initialPageSize: string, pageSize: string, forceSearch: boolean, prevPageSize: string, selectedOrderingField: string, isDebug: boolean, searchLoaderIsActive: boolean, searchTerm: Ref<UnwrapRef<string>>, requestInitializationCallDone: boolean, componentVisible: boolean, contentItems: Ref<UnwrapRef<*[]>>, showAlert: boolean, contentFromValue: number, prevSearchTerm: Ref<UnwrapRef<string>>, triggeredBySearchTerm: boolean, isNewSearch: boolean}}
     */
    getDefaultStateOnPageSizeChange() {
      return  {
        isDebug: false,
        requestInitializationCallDone: false,
        forceSearch: false,
        searchLoaderIsActive: false,

        pageSize: localStorage.getItem('pageSize'),
        initialPageSize: localStorage.getItem('initialPageSize'),
        prevPageSize: localStorage.getItem('defaultPageSize'),

        contentFromValue: 0,

        // Search can be triggered by SEARCH, PAGE SIZE CHANGE or by SCROLL
        // this flag is true when search is triggered by searchTerm typing
        triggeredBySearchTerm: false,

        // the fetch by searchTerm can be executed
        fetchBySearchEnabled: false,
        fetchByPageSizeEnabled: false,

        totalNumberOfRecords: ref(0),
        searchTerm: ref(''),
        prevSearchTerm: ref(''),
        contentItems: ref([]),

        selectedOrderingField: "ordering_main_category",

        // is true when the searchTerm is a new searchTerm
        isNewSearch: true,

        // the search loading indicator status
        searchInProgress: false,

        showAlert: false,

        orderingEnabled: false,
        editorToolEnabled: false,
        orderingHasChanged: false,
        backupContentItems: null,
        componentVisible: false,
        // defaultTaxonomy: {},
      }
    },
    /**
     *
     * @returns {boolean|(function(): boolean)|*}
     */
    isSearchLoaderActive() {
      return this.searchLoaderIsActive;
    } /**
     *
     * @param state
     * @returns {*}
     */,
    controlMessage(state) {
      return state.control;
    } /**
     *
     * @param state
     * @returns {[]|*}
     */,
    getContentItems(state) {
      return state.contentItems;
    }
  },
  actions: {

    /**
     *
     * @returns {boolean}
     */
    hasRole(role) {

      return Array.isArray(this.getRoles()) ?
        this.getRoles().includes(role) : false;
    },

    /**
     *
     * @returns {boolean}
     */
    hasRoleAdmin() {
      return this.hasRole('ROLE_ADMIN');
    },
    /**
     *
     * @returns {boolean}
     */
    hasRoleWebservice() {
      return this.hasRole('ROLE_WEBSERVICE');
    },
    /**
     *
     * @returns {boolean}
     */
    hasRoleDeveloper() {
      return this.hasRole('ROLE_DEVELOPER');
    },
    /**
     *
     * @returns {boolean}
     */
    canAccessBackend() {
      return this.hasRoleAdmin() || this.hasRoleDeveloper() || this.hasRoleWebservice();
    },
    /**
     *
     * @returns {*[]}
     */
    getRoles() {
      return this.backendRoles;
    },
    /**
     * Initialize store for a fresh/new call
     * All state flags and values are initialized
     */
    initializeForNewSearch() {
      // this is a new search based on a new searchTerm
      // this state has to be treated as a completely initial state,
      this.isNewSearch = true;
      this.requestInitializationCallDone = false;
      this.resetContentItems();
    },
    resetContentItems() {
      this.removeContentItems();
      this.resetContentItemsLength();
    },
    /**
     *
     * @param searchTerm
     * @param triggeredBySearch
     */
    setSearchTerm(searchTerm, triggeredBySearch = false) {
      this.triggeredBySearchTerm = triggeredBySearch;
      if (searchTerm) {
        this.searchTerm = searchTerm;
        this.isDebug && console.log('Store search term set to : ', this.searchTerm);
      }
    },
    /**
     *
     */
    removeContentItems() {
      this.contentItems = [];
      // this.store.contentFromValue = 0;
      this.totalNumberOfRecords = 0;
    },
    /**
     *
     */
    resetContentItemsLength() {
      this.totalNumberOfRecords = 0;
    },
    /**
     *
     * @param searchData
     */
    prepareRequestData(searchData) {
      const searchTerm = _.get(searchData, 'searchTerm', false);
      const from = this.contentFromValue;
      const order = _.get(searchData, 'order', '');
      const pageSize = _.get(searchData, 'pageSize', this.defaultPageSize);
      const contentType = _.get(searchData, 'contentType', 'pages');
      var taxonomy = _.get(searchData, 'taxonomy', {});

      const searchedExpression = 'c|' + (searchTerm ? searchTerm : '');
      const requestData = {
        searchTerms: {
          title: searchedExpression,
          teaser: searchedExpression,
          body: searchedExpression,
        },
        from,
        pageSize,
        contentType,
        order,
        taxonomy: this.getValidTaxonomy(taxonomy),
      }

      this.isDebug && console.log('** Search will use data: \n', requestData);

      return requestData;
    },
    /**
     *
     * @returns {any}
     */
    getDefaultTaxonomy() {
      return JSON.parse(JSON.stringify(this.defaultTaxonomy));
    },
    /**
     *
     * @param taxonomy
     * @returns {{}|any}
     */
    getValidTaxonomy(taxonomy) {

      this.isDebug &&
      console.log(
        'getValidTaxonomy():\nINPUT TAXONOMY is ',
        taxonomy,
        JSON.stringify(taxonomy),
        '\n with type: ',
        typeof taxonomy,
      );
      this.isDebug && console.log(Array.isArray(taxonomy));

      if (Array.isArray(taxonomy) && taxonomy.length) {
        return taxonomy;
      }

      if (JSON.stringify(taxonomy) === '{}') {
        return this.getDefaultTaxonomy();
      }
      switch (taxonomy) {
        case undefined:
        case null:
        case '':
          this.isDebug && console.log('OUTPUT TAXONOMY is default: ', this.getDefaultTaxonomy());
          return this.getDefaultTaxonomy();

        default:
          this.isDebug && console.log('OUTPUT TAXONOMY is  ', typeof taxonomy, taxonomy);
          if('object' === typeof taxonomy) {
            this.isDebug && console.log('OUTPUT TAXONOMY is  ', JSON.parse(JSON.stringify( taxonomy)));
            return taxonomy;
          }
          return JSON.parse(taxonomy);
      }
    } /**
     *
     * @param orderingData
     * @returns {*}
     */,
    saveOrdering(orderingData) {

      this.isDebug && console.log('The new ordering data: ', orderingData);

      return CustomContentService.saveOrdering(orderingData)
        .then(
          (response) => {
            this.isDebug && console.log('Response ', response);
            this.orderingHasChanged = false;
          },
          (error) => {
            this.isDebug && console.log('Error ', error);
          },
        )
        .catch((exception) => {
          this.isDebug && console.log('Exception: ', exception);
        })
    }
    /**
     *
     * @param orderingData
     * @returns {*}
     */,
    saveParameter(data) {

      this.isDebug && console.log('Saving data: ', data);

      return CustomContentService.saveData(data)
        .then(
          (response) => {
            this.isDebug && console.log('Response ', response);
          },
          (error) => {
            this.isDebug && console.log('Error ', error);
          },
        )
        .catch((exception) => {
          this.isDebug && console.log('Exception: ', exception);
        })
    }

    /**
     *
     * @param data
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */,
    fetchListing(data) {
      return CustomContentService.fetchListing(data);
    }

    /**
     *
     * @param data
     * @returns {Promise<void>}
     */,
    appendFetchedItems(data, fetchNews = false) {
      this.isDebug && console.log('Fetch and append..', data);
      const service = fetchNews
        ? CustomContentService.fetchNews()
        : CustomContentService.fetchListing(data);

      this.isDebug && console.log('SERVICE in use: ', service);
      return service.then(
        (response) => {

          if (_.has(response, 'data.total')) {
            if (0 !== response.data.total) {
              response.data.data.forEach((dataItem) => {
                this.contentItems.push(dataItem);
                this.isDebug && console.log('Content type is: ', dataItem.contentType);
              });
              this.totalNumberOfRecords = response.data.total;
              this.requestInitializationCallDone = true;
              this.isDebug && console.log('Store has ', this.contentItems.length, ' elements.');
              this.showAlert = false;
              return;
            }
          }

          this.totalNumberOfRecords = 0;
          this.contentItems = [];
          this.requestInitializationCallDone = true;
          this.showAlert = true;
          this.isDebug && console.log('Store has ', this.contentItems.length, ' elements.');
        },
        (error) => {
          this.isDebug && console.log('appendFetchedItems error: ', error);
          alert('HIBA: ' + error.response.data.message);
        },
      )
    }
  }
})
