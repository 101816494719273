<template>
    <div>

    </div>
</template>

<script>
import {useCheckSession} from "@/composables/checkSession.js";
import {useCustomContentStore} from "@/stores/CustomContentStore.js";
import _ from "lodash";

export default {
    setup() {

        const isDebug = false;
        const store = useCustomContentStore();
        const checkSession = useCheckSession();
        checkSession.fetchSession().then(result => {
            // console.log("CheckSession: ", JSON.stringify(result));
            if (true === result) {
                store.componentVisible = true;
                store.backendSession = true;
                store.backendRoles = ['ROLE_WEBSERVICE', 'ROLE_ADMIN'];
                return;
            }
            if (false === result) {
                store.componentVisible = false;
                store.backendSession = false;
                store.backendRoles = [];
                return;
            }
            if (Array.isArray(result)) {
                store.componentVisible = true;
                store.backendSession = true;
                store.backendRoles = _.get(store.backendRoles(), '0.roles', []);
                return;
            }

        });

        return {isDebug, store, checkSession};
    }
}
</script>

<style lang="scss" scoped>

</style>
