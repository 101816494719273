import { createPinia } from 'pinia';
import Axios from 'axios';
// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';



// jquery
import jquery from 'jquery';

// Vue.config.devtools = true


// mounter
import mounter from './mounter.js';
import './assets/main.css';

// import on components
import ContentSearch from './components/ContentSearch.vue';
import ContentListing from './components/ContentListing.vue';
import DisplayResultsNumber from '@/components/DisplayResultsNumber.vue';
import DownloadsFilter from '@/components/DownloadsFilter.vue';
import UtilityMenu from '@/components/UtilityMenu.vue';
import CheckSession from "@/components/CheckSession.vue";

// Axios
Axios.defaults.headers.common = {
  accept: 'application/json',
  // accept: 'application/vnd.api+json',
};

window.jQuery = jquery;
if(!window.$) {
  window.$ = jquery;
}

const vuetify = createVuetify({
  components,
  directives,
});

const pinia = createPinia();

jquery(document).ready(() => {



  mounter.mountVueComponent(
    'check-session',
    CheckSession,
    [
      {
        library: pinia,
      },
    ]);

  mounter.mountVueComponent(
    'downloads-filter',
    DownloadsFilter,
    [
      {
        library: vuetify,
        options: {
          unstyled: true,
        },
      },
      {
        library: pinia,
      },
    ]);

    mounter.mountVueComponent(
      'utility-menu',
      UtilityMenu,
      [
        {
          library: vuetify,
          options: {
            unstyled: true,
          },
        },
        {
          library: pinia,
        },
      ]);
    mounter.mountVueComponent(
      'content-listing',
      ContentListing,
      [
        {
          library: vuetify,
          options: {
            unstyled: true,
          },
        },
        {
          library: pinia,
        },
      ]);

    mounter.mountVueComponent(
      'content-search',
      ContentSearch,
      [
        {
          library: vuetify,
          options: {
            unstyled: true,
          },
        },
        {
          library: pinia,
        },
      ]);

    mounter.mountVueComponent(
      'display-results-number',
      DisplayResultsNumber,
      [
        {
          library: pinia,
        },
      ]);
  });
