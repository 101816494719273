import axios from 'axios';

const SITE_URI = 'https://oktoklima.hu';


export default axios.create({
  baseURL: SITE_URI,
  timeout: 5000,
  // headers: {
  //
  // },
});
