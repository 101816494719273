/* eslint-disable import/extensions, import/no-unresolved, no-unused-vars, no-console */
/**
 * v20240313
 */
import { createApp } from 'vue';



// Vue.config.devtools = true
// import console from "@/console";

export default {

  debug: false,
  mountedComponents: [], // TODO
  mountVueComponent(tagName, vueComponent, dependencies = [], options = {}) {

    if (!tagName) {
      this.debug && console.log('tagName is empty...');
      return;
    }

    this.debug && console.log('============= Mounting an other component =============');
    this.debug && console.log('Tag is ', tagName.trim(), '.');

    const requestedDOMElements = document.getElementsByTagName(tagName);

    if (0 === requestedDOMElements.length) {
      this.debug && console.log('Tag is not used in HTML document.');
      return;
    }

    const domElements = Array.from(requestedDOMElements);

    this.debug && console.log('We found ', domElements.length, ' requested dom elements for this tag.');

    domElements.forEach((requestedDOMElement) => {

      const attributes = {
        id: (Math.random() + 1).toString(36).substring(7),
      };

      if (requestedDOMElement.hasAttributes()) {
        Array.from(requestedDOMElement.attributes).forEach((attribute) => {

          let attributeName = attribute.name.startsWith(':') ?
            attribute.name.substr(1) : attribute.name;
          attributes[attributeName] = attribute.value;
        });
        this.debug && console.log('Dom element has props/attributes', attributes);
      }

      const mountableComponentSelector = `mounted-component-${attributes.id}`;
      const mountableElement = document.createElement('div');

      mountableElement.setAttribute('id', `${mountableComponentSelector}--holder`);
      mountableElement.setAttribute(
        'class',
        `mounted-component ${mountableComponentSelector}`,
      );
      const componentInstance = createApp(vueComponent, {
        ...attributes,
        id: `${attributes.id}-component`,
      });
      this.debug && console.log('Component ', attributes.id, ' has ', dependencies.length, ' dependencies ');
      this.debug && console.log('Component attributes ', JSON.stringify({
        ...attributes,
        id: `${attributes.id}-component`,
      }));

      if (Array.from(dependencies).length) {

        Array.from(dependencies).forEach((dependency) => {
          if (dependency['library'] !== undefined) {
            const dependencyOptions =
              dependency['options'] === undefined ?
                {} :
                dependency.options;

            this.debug && console.log(
              'Adding library to component instance.'
            );
            componentInstance.use(dependency.library, dependencyOptions);
          }
        });
      }
      requestedDOMElement.appendChild(mountableElement);
      componentInstance.mount(`#${mountableComponentSelector}--holder`);
      this.mountedComponents.push(componentInstance);

    });

  },
  getMountedComponents() {
    return this.mountedComponents;
  },
};
