import customAxios from './customAxios';

export default {

  isDebug: false,

  fetchListing(messageData) {
    const serviceUrl = '/api/custom-search';
    const axiosOptions = {
      method: 'post',
      // transformRequest: [(data, headers) => {
      //   // transform the data
      //   return data;
      // }],
      data: messageData,
      headers: {
        'Content-Type': 'text/json',
        // 'Access-Control-Allow-Origin': 'http://localhost:5173',
        // 'Access-Control-Allow-Headers': '*',
      },
    };

    this.isDebug && console.log('AXIOS service URL: ', serviceUrl);
    this.isDebug && console.log('AXIOS service messageData: ', messageData);
    this.isDebug && console.log('AXIOS options: ', axiosOptions);

    return customAxios.post(serviceUrl, messageData, axiosOptions);
  },

  /**
   *
   * @param messageData
   * @returns {Promise<customAxios.AxiosResponse<any>>}
   */
  saveOrdering(messageData) {
    const serviceUrl = '/api/save-content';
    this.isDebug && console.log('CustomContentService::saveOrdering() data is ', messageData);
    const axiosOptions
      = {
      method: 'post',
      // transformRequest: [(data, headers) => {
      //   // transform the data
      //   return data;
      // }],
      data: messageData,
      headers: {
        'Content-Type': 'text/json',
        // 'Access-Control-Allow-Origin': 'http://localhost:5173',
        // 'Access-Control-Allow-Headers': '*',
      },

    };

    this.isDebug && console.log('AXIOS service URL: ', serviceUrl);
    this.isDebug && console.log('AXIOS service messageData: ', messageData);
    this.isDebug && console.log('AXIOS options: ', axiosOptions);

    return customAxios.post(serviceUrl, messageData, axiosOptions);
  },

  /**
   *
   * @returns {Promise<customAxios.AxiosResponse<any>>}
   * @param {key:value<any>} data
   */
  saveData(data) {
    const serviceUrl = '/api/save-data';
    this.isDebug && console.log('CustomContentService::saveData() data is ', data);
    const axiosOptions
      = {
      method: 'post',
      // transformRequest: [(data, headers) => {
      //   // transform the data
      //   return data;
      // }],
      data: data,
      headers: {
        'Content-Type': 'text/json',
        // 'Access-Control-Allow-Origin': 'http://localhost:5173',
        // 'Access-Control-Allow-Headers': '*',
      },

    };

    this.isDebug && console.log('AXIOS service URL: ', serviceUrl);
    this.isDebug && console.log('AXIOS service messageData: ', data);
    this.isDebug && console.log('AXIOS options: ', axiosOptions);

    return customAxios.post(serviceUrl, data, axiosOptions);
  },
  fetchNews(messageData) {
    const serviceUrl = '/api/news';
    // console.log('CustomContentService::fetchListing() data is ', messageData);
    const axiosOptions
      = {
      method: 'post',
      // transformRequest: [(data, headers) => {
      //   // transform the data
      //   return data;
      // }],
      data: messageData,
      headers: {
        'Content-Type': 'text/json',
        // 'Access-Control-Allow-Origin': 'http://localhost:5173',
        // 'Access-Control-Allow-Headers': '*',
      },

    };

    this.isDebug && console.log('AXIOS service URL: ', serviceUrl);
    this.isDebug && console.log('AXIOS service messageData: ', messageData);
    this.isDebug && console.log('AXIOS options: ', axiosOptions);

    return customAxios.post(serviceUrl, messageData, axiosOptions);
  },
};
