<template>
    <div class="search--field-group row">
        <div
            :class="{'col-lg-6':productCategoryFilterVisible, 'col-lg-8':!productCategoryFilterVisible }"
            class="col-xs-12 col-sm-12 col-md-12">
            <VTextField
                v-model="searchTerm"
                @update:modelValue="handleValueChanged"
                label="Keresés"
                bgColor="#EDEDED"
            >
                <template v-slot:prepend-inner>
                    <v-icon
                        @click="clearSearchTerm()"
                        icon="bi bi-x-lg"
                    ></v-icon>
                </template>
                <template v-slot:append-inner>
                    <v-icon
                        @click="executeSearch()"
                        icon="bi bi-search"
                    ></v-icon>
                </template>
                <template #loader>
                    <v-progress-linear
                        :active="searchInProgress"
                        color="#8BC750"
                        height="5"
                        style="position: relative;"
                        indeterminate
                    ></v-progress-linear>
                </template>
            </VTextField>
        </div>
        <div
            class="col-lg-4 col-xs-12 col-sm-12 col-md-12"
            v-if="true === productCategoryFilterVisible">
            <v-select
                label="Alkalmazott terméktípus szerint"
                :items="normalizedProductCategories"
                v-model="selectedProductCategory"
                :model-value="selectedProductCategoryValue"
                :return-object="true"
                bgColor="#EDEDED"
                @update:modelValue="setProductCategoryValue();handleProductCategoryChanged()"
            ></v-select>
        </div>
        <div
            :class="{'col-lg-2': productCategoryFilterVisible, 'col-lg-4': !productCategoryFilterVisible }"
            class="col-lg-2 col-xs-12 col-sm-12 col-md-12">
            <v-combobox
                label="Cikkek száma/oldal"
                :items="[6, 12, 18, 24, 48, 96]"
                bgColor="#EDEDED"
                v-model="store.pageSize"
                @update:modelValue="handlePageSizeChanged"
            ></v-combobox>
        </div>

    </div>
</template>

<script>
import {ref, watch} from 'vue';
import {debounce} from 'lodash';
import {useCustomContentStore} from '@/stores/CustomContentStore.js';
import {mapState} from 'pinia';
import {VCombobox, VIcon, VProgressLinear, VSelect} from 'vuetify/components';

export default {
    name: 'ContentSearch',
    setup(props) {
        const isDebug = false;
        const store = useCustomContentStore();

        store.totalNumberOfRecords = props.initialValue;

        isDebug && console.log('Setting up component ContentSearch.');
        const searchTerm = ref('');

        isDebug && console.log('searchInProgress is ', store.isSearchLoaderActive);
        isDebug && console.log("Before execute Search");

        const executeSearch = debounce((newValue, oldValue) => {

            isDebug && console.log('Search Term old value ', oldValue);
            isDebug && console.log('Search Term new value ', newValue);

            if (newValue === oldValue) {
                // no change done
                isDebug && console.log('Search term not changed...');
                return;
            }
            if ('' === searchTerm.value) {
                isDebug && console.log('Search term empty...');
                return;
            }

            store.triggeredBySearchTerm = true;
            store.fetchBySearchEnabled = true;
            store.searchInProgress = true;

            store.initializeForNewSearch();
            store.setSearchTerm(searchTerm.value, true);

            isDebug && console.log('Using searchterm: ', searchTerm.value);
            isDebug && console.log('executeSearch()', store.fetchBySearchEnabled);
        }, 500);

        isDebug && console.log("Before execute watch");

        watch(searchTerm, (newValue, oldValue) => {
            // noinspection JSCheckFunctionSignatures
            executeSearch(newValue, oldValue);
        });

        return {
            selectedProductCategory: null,
            selectedProductCategoryValue: ref(""),
            searchTerm,
            store,
            showPcFilter: props.showProductCategoryFilter
        };
    },
    mounted() {

    },
    props: {
        initialValue: {
            type: Number,
            default: () => {
                return 0;
            },
        },
        taxonomy: {
            type: Object,
            default: () => {
                return {};
            },
        },
        showProductCategoryFilter: {
            type: Boolean,
            default: false
        },
        productCategoriesList: {
            type: String,
            default: () => {
                return '[]';
            },
        },
    },
    components: {
        'v-icon': VIcon,
        'v-progress-linear': VProgressLinear,
        'v-combobox': VCombobox,
        'v-select': VSelect,
    },

    methods: {
        setProductCategoryValue() {
            console.log("SELECTED category: ", this.selectedProductCategory);
            this.selectedProductCategoryValue = this.selectedProductCategory;
        },
        handleProductCategoryChanged() {
            console.log("Product category changed....");
            console.log("Default Taxonomy: ", this.store.getDefaultTaxonomy());
            console.log("Request Taxonomy: ", this.store.requestTaxonomy);
            console.log("Request product category: ", this.selectedProductCategory);

            this.store.$patch(state => {
                state.triggeredByProductCategory = true;
                state.fetchByProductCategoryEnabled = true;
                state.requestInitializationCallDone = false;
                state.contentItems = [];
                state.totalNumberOfRecords = 0;

                const taxonomy = this.store.getValidTaxonomy(state.taxonomy);
                console.log("TAXONOMY ISSUES: ",
                    typeof state.taxonomy,
                    state.taxonomy
                );

                if ("" === this.selectedProductCategoryValue.value) {
                    if (taxonomy.product_types) {
                        delete taxonomy.product_types;
                    }
                    state.taxonomy = taxonomy;
                } else {
                    state.taxonomy = {
                        ...taxonomy,
                        product_types: {
                            type: 'product_types',
                            slug: [this.selectedProductCategory.value]
                        }
                    }
                }
            })

            console.log('handleProductCategoryChanged() final taxonomy: ', this.store.taxonomy);
        },
        handleValueChanged() {},
        handlePageSizeChanged() {

            localStorage.setItem('initialPageSize', 2 * this.store.pageSize);
            localStorage.setItem('pageSize', this.store.pageSize);

            this.isDebug && console.log('handlePageSizeChanged() ', this.store.pageSize);

            if (this.store.pageSize >= this.store.prevPageSize) {
                this.store.prevPageSize = this.store.pageSize;
            } else {
                this.isDebug && console.log("Kissebbet valasztott");
            }
            this.store.triggeredByPageSizeChanged = true;
            this.store.fetchByPageSizeEnabled = true;

            // this.store.initializeForNewSearch();
            // TODO: ajax call to place it int session storage
        },
        /**
         *
         */
        executeSearch() {
            this.isDebug && console.log('executeSearch()');
            this.store.forceSearch = true;
        },
        /**
         *
         */
        clearSearchTerm() {
            this.isDebug && console.log('clearSearchTerm()');
            this.searchTerm = '';
            this.store.$patch({
                searchTerm: '',
                forceSearch: true,
                fetchBySearchEnabled: true,
                triggeredBySearchTerm: true,
            });
        },

    },
    /**
     *
     */
    computed: {

        ...mapState(useCustomContentStore, [
                'contentItems',
            ],
        ),
        productCategoryFilterVisible() {
            if(typeof this.showProductCategoryFilter === 'string') {
                return this.showProductCategoryFilter === 'true';
            }
            return this.showProductCategoryFilter;
        },
        /**
         *
         * @returns {any}
         */
        productCategoriesListObject() {

            return JSON.parse(this.productCategoriesList);

        },
        /**
         *
         * @returns {{title: *, value: *}[]}
         */
        normalizedProductCategories() {

            console.log("normalizedProductCategories: ", this.productCategoriesListObject);
            const optionNames = Object.keys(this.productCategoriesListObject);
            console.log("normalizedProductCategories: ", optionNames);
            return [
                {
                    value: "",
                    title: "",
                },
                ...optionNames.map(optionName => {
                    const optionTitle = this.productCategoriesListObject[optionName];
                    return {
                        value: optionName,
                        title: optionTitle,
                    };
                })
            ];
        },
        searchInProgress() {
            this.isDebug && console.log("COMPUTED searchInProgress", this.store.searchInProgress === true);
            return this.store.searchInProgress === true;
        }
        // ...mapActions(useCustomContentStore,['getContentItems'])
    },
};
</script>


<style>
.bi {
    font-weight: bold;
}
.bi:hover {
    cursor: pointer;
}
</style>
